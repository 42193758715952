<template>
  <v-container>
    <BaseList
      flat
      color="primary"
      :title="$t('transfer_request_recurrent_requests')"
      :section="$route.meta.section"
      :headers="headers"
      :items="items"
      :loading="loading"
      :totalRows="totalRows"
      :breadcrumbs="false"
      :elevation="0"
      :height="'100%'"
      :buttons="{ search: true, edit: true, delete: true }"
      @edit="handleEdit"
      @refresh="getData"
      @delete="handleDelete"
      @options="handleOptions"
      @search="handleSearch"
      :search="search"
    >
      <template #item.data.amount="{ value }">
        <span> {{ value | currency }} </span>
      </template>
      <template #item.schedule.start="{ value }">
        <span> {{ value | date('DD/MM/YYYY') }} </span>
      </template>
      <template #item.schedule.end="{ value }">
        <span> {{ value | date('DD/MM/YYYY') }} </span>
      </template>
      <template #item.operationType="{ value }">
        <span>{{
          value == 'W'
            ? $t('transfer_request_type_withdrawal')
            : $t('transfer_request_type_deposit')
        }}</span>
      </template>
    </BaseList>
  </v-container>
</template>

<script>
import BaseList from '@/components/commons/BaseList'
import ClientTransferSchedulesService from '@/services/clientTransferSchedules'
import RecurrenceEditModal from './RecurrenceEditModal'
import { mapState } from 'vuex'

export default {
  components: { BaseList },
  data() {
    return {
      options: {},
      dialog: false,
      loading: false,
      totalRows: 0,
      actives: [],
      search: '',
      certification: {},
      headers: [
        { text: 'transfer_index_alias', value: 'data.avsInternalRemark' },
        {
          text: 'transfer_request_instrument_type',
          value: 'data.fundType',
          width: '150'
        },
        { text: 'transfer_request_amount', value: 'data.amount', align: 'end' },
        {
          text: 'transfer_request_business_day',
          value: 'schedule.day',
          width: '120',
          align: 'center'
        },
        {
          text: 'transfer_request_recurrent_frequency',
          value: 'schedule.monthlyFrequency',
          width: '120',
          align: 'center'
        },
        {
          text: 'transfer_request_recurrent_start_date',
          value: 'schedule.start'
        },
        { text: 'transfer_request_end_day', value: 'schedule.end' }
      ],
      items: []
    }
  },
  async created() {
    await this.getData()
  },
  computed: {
    ...mapState('account', ['account'])
  },
  methods: {
    async getData() {
      this.loading = true
      try {
        const { data } = await ClientTransferSchedulesService.find(
          this.account.code
        )
        this.items = data
        this.totalRows = this.items.length ?? 0
      } catch (error) {
        if (error.response.status !== 401)
          this.$dialog.message.error('No se pudo cargar la información.')
      } finally {
        this.loading = false
      }
    },
    handleOptions(options) {
      this.options = options
      this.getData()
    },
    async handleEdit(item) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: '60%',
        transition: 'dialog-top-transition',
        clientRequest: item
      }

      const success = await this.$dialog.showAndWait(
        RecurrenceEditModal,
        params
      )
      if (success) {
        this.$dialog.notify.success(this.$t('general_save_successfully_label'))
        this.getData()
      }
    },
    handleSearch(search) {
      this.search = search
    },
    async handleDelete(item) {
      const res = await this.$dialog.warning({
        title: this.$t('general_confirmation_label'),
        text: this.$t('general_delete_question', {
          record: `<strong>${item.data.avsInternalRemark}</strong>`
        }),
        showClose: false
      })

      if (!res) return false

      try {
        this.loading = true
        await ClientTransferSchedulesService.delete(
          item.accountCode,
          item.transferScheduleId
        )
        this.$dialog.notify.success(
          this.$t('general_delete_successfully_label')
        )
        await this.getData()
      } catch (e) {
        this.$dialog.message.error(e.title)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.v-link-dialog {
  text-align: left;
  font: normal normal bold 14px/17px Lato;
  letter-spacing: 0px;
  color: #00559e;
  opacity: 1;
}
.login-button {
  width: 208px;
  background: transparent linear-gradient(112deg, #00559e 0%, #0090df 100%) 0%
    0% no-repeat padding-box;
  color: white !important;
  box-shadow: 6px 10px 14px #00000029;
  border-radius: 28px;
  opacity: 1;
}

.v-title-card {
  text-align: left;
  font: normal normal bold 16px/38px Lato;
  letter-spacing: 0px;
  color: #212529;
  opacity: 1;
}
</style>
