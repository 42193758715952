var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('BaseList',{attrs:{"flat":"","color":"primary","title":_vm.$t('transfer_request_recurrent_requests'),"section":_vm.$route.meta.section,"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"totalRows":_vm.totalRows,"breadcrumbs":false,"elevation":0,"height":'100%',"buttons":{ search: true, edit: true, delete: true },"search":_vm.search},on:{"edit":_vm.handleEdit,"refresh":_vm.getData,"delete":_vm.handleDelete,"options":_vm.handleOptions,"search":_vm.handleSearch},scopedSlots:_vm._u([{key:"item.data.amount",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(value))+" ")])]}},{key:"item.schedule.start",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(value,'DD/MM/YYYY'))+" ")])]}},{key:"item.schedule.end",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(value,'DD/MM/YYYY'))+" ")])]}},{key:"item.operationType",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value == 'W' ? _vm.$t('transfer_request_type_withdrawal') : _vm.$t('transfer_request_type_deposit')))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }