<template>
  <v-container>
    <VWidget
      :title="$t('transfer_request_operations_withdrawal')"
      :loading="loading"
    >
      <template v-slot:content>
        <v-tabs active-class="tab-active">
          <v-tab>
            {{ $t('transfer_request_actives_operations_withdrawal') }}
          </v-tab>
          <v-tab>
            {{ $t('transfer_request_history_operations_withdrawal') }}
          </v-tab>
          <!-- Transfer Request Recurrent -->
          <v-tab>
            {{ $t('transfer_request_recurrent_requests') }}
          </v-tab>
          <v-tab-item class="my-6">
            <actives />
          </v-tab-item>
          <v-tab-item class="my-6">
            <historics />
          </v-tab-item>
          <!-- Transfer Request Recurrent -->
          <v-tab-item class="my-6">
            <recurrences />
          </v-tab-item>
        </v-tabs>
      </template>
    </VWidget>
  </v-container>
</template>
<script>
import VWidget from '@/components/commons/VWidget'
import Actives from './partials/Actives'
import Historics from './partials/Historics'
import Recurrences from './partials/recurrences/Index'
export default {
  components: {
    VWidget,
    Actives,
    Historics,
    Recurrences
  },
  data() {
    return {
      loading: false
    }
  }
}
</script>

<style scoped>
.login-button {
  width: 208px;
  min-width: 130px !important;
  background: transparent linear-gradient(112deg, #00559e 0%, #0090df 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 6px 10px 14px #00000029;
  border-radius: 28px;
  opacity: 1;
}
.v-tab {
  text-transform: none !important;
  font-weight: bold;
}
</style>
