<template>
  <div class="container">
    <v-widget :title="$t('transfer_request_title')" :loading="loading">
      <template #content>
        <v-stepper v-model="step" alt-labels flat class="mt-6 elevation-0">
          <v-stepper-header elevation="0" class="elevation-0">
            <template v-for="(item, i) in steps">
              <v-stepper-step
                :key="`${i}-step`"
                :complete="step > i + 1"
                :step="i + 1"
              >
                <span class="text-center">{{ $t(item.title) }}</span>
              </v-stepper-step>
              <v-divider :key="i" v-if="i + 1 < steps.length" />
            </template>
          </v-stepper-header>
          <!-- COMPONENTS -->
          <v-stepper-items>
            <v-stepper-content
              v-for="(item, i) in steps"
              :key="`step-content-${i}`"
              :step="i + 1"
              class="mt-0 pa-0"
            >
              <ValidationObserver ref="observer" v-slot="{ invalid }">
                <component
                  :is="item.component"
                  v-bind="{
                    clientRequest,
                    currencies,
                    cashAvailable,
                    operation,
                    instructions,
                    invalid
                  }"
                  :canPrevious="step > 1"
                  :canNext="step < steps.length"
                  :canSubmit="step === steps.length"
                  @next="
                    handleNext(step)
                      ? (step = handleNext(step))
                      : $router.push({ name: 'PermanentInstructionsCreate', query: { redirect: 'Retire' } })
                  "
                  @previous="step = step - 1"
                  @submit="handleSubmit"
                  @cancel="handleCancel"
                  @clear="handleClear"
                  @selectInstruction="handleInstruction"
                  @clearInstruction="clearInstruction"
                  @clearSchedule="clearSchedule"
                />
              </ValidationObserver>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </template>
    </v-widget>
  </div>
</template>

<script>
import BaseForm from '@/components/commons/BaseForm'
import VWidget from '@/components/commons/VWidget'
import PermanentInstructions from './partials/PermanentInstructions'
import Request from './partials/Request'
import Recurrence from './partials/Recurrence'
import Observations from './partials/Observations'
import Finish from './partials/Finish'
import ClientTransferRequestService from '@/services/clientTransferRequest'
import ClientTransferInstructionsService from '@/services/clientTransferInstructions'
import CurrencyService from '@/services/currency'
import CashAvailableService from '@/services/cashAvailable'
import { mapActions, mapState } from 'vuex'
import TwoFactorModal from '@/components/commons/TwoFactorModal.vue'

export default {
  components: {
    BaseForm,
    VWidget,
    PermanentInstructions,
    Request,
    Observations,
    Recurrence,
    Finish
  },
  props: {
    operation: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      stepsItems: [
        {
          title: 'transfer_request',
          component: 'Request'
        },
        {
          title: 'transfer_request_instruction',
          component: 'PermanentInstructions'
        },
        {
          title: 'transfer_request_recurrence',
          component: 'Recurrence'
        },
        {
          title: 'transfer_request_observations',
          component: 'Observations'
        },
        {
          title: 'transfer_finalize',
          component: 'Finish'
        }
      ],
      step: 1,
      loading: false,
      clientRequest: {
        fundType: 'cash',
        operationType: '',
        transferInstructionId: '',
        amount: null,
        currency: '',
        currencyCode: '',
        operationCurrencyCode: '',
        description: '',
        avsInternalRemark: '',
        bnfReference: '',
        dollarFunds: null,
        permanentInstruction: {},
        instructionType: '',
        schedule: {
          recurrent: false,
          monthlyFrequency: 1,
          day: 1,
          start: '',
          end: ''
        }
      },
      currencies: [],
      cashAvailable: null,
      instructions: []
    }
  },
  computed: {
    ...mapState('account', ['account', 'authorityAccounts']),
    ...mapState('transfer', [
      'transferRedirect',
      'permanentInstruction',
      'clientRequestData'
    ]),
    ...mapState('setting', ['settings']),
    ...mapState('auth', ['user']),
    ...mapState('language', ['idiom']),

    isExecutive() {
      return this.user?.authorityLevelsCode === 1
    },

    canPrevious() {
      return this.step > 1
    },
    // Dynamic Menu
    steps() {
      let data = [...this.stepsItems]

      switch (this.operation) {
        case 'D':
          data.splice(2, 1)
          break
        case 'W':
          data
          break
      }

      if (
        this.clientRequest.instructionType ==
          'transfer_request_new_instruction' ||
        this.clientRequest.permanentInstruction?.fromIndex
      )
        data.splice(1, 1)

      return data
    }
  },
  async created() {
    if (this.handleAuthorityAccounts() && !this.isExecutive) {
      this.$router.push({ name: 'Home' })
    }

    if (this.permanentInstruction.fromIndex)
      this.clientRequest.permanentInstruction = this.permanentInstruction

    this.handleRedirect()
    await this.getPermanentInstructions()
    await this.getCashAvailable()
    await this.getCurrencies()
    let twoFactor = this.settings.find(
      item =>
        item.key ===
        (this.operation == 'D'
          ? '2fa_deposit_notifications'
          : '2fa_transfer_requests')
    )
    if (twoFactor.value && !this.user.twofactor_habilitated) {
      let response = await this.handleTwoFactorModal(
        this.user.twofactor_habilitated
      )
      if (!response) {
        this.loading = true
        this.$dialog.message.error(this.$t('twofactor_required'))
        this.$router.push({ name: 'Home' })
      }
    }
  },
  methods: {
    ...mapActions('transfer', [
      'setTransferRedirect',
      'setClientRequest',
      'setPermanentInstruction'
    ]),

    async getPermanentInstructions() {
      this.loading = true
      try {
        let data = await ClientTransferInstructionsService.find(
          this.account.code
        )
        this.instructions = data
      } catch (error) {
        if (error?.response?.status !== 401)
          this.$dialog.message.error('No se lograron obtener las Instrucciones de Transferencias.')
      } finally {
        this.loading = false
      }
    },
    handleAuthorityAccounts() {
      return (
        this.authorityAccounts.findIndex(
          item => item.code == this.account.code
        ) > -1
      )
    },
    handleRedirect() {
      if (this.transferRedirect) {
        this.step = 2
        this.clientRequest.permanentInstruction = this.permanentInstruction
        this.clientRequest.amount = this.clientRequestData.amount
        this.clientRequest.currency = this.clientRequestData.currency
        this.clientRequest.schedule.start = this.clientRequestData.schedule.start
        this.clientRequest.instructionType = 'transfer_request_new_instruction'
        this.clearState()
      }
    },
    clearState() {
      this.setClientRequest({})
      this.setPermanentInstruction({})
      this.setTransferRedirect(false)
    },
    async handleSubmit() {
      try {
        let twoFactor = this.settings.find(
          item =>
            item.key ===
            (this.operation == 'D'
              ? '2fa_deposit_notifications'
              : '2fa_transfer_requests')
        )
        if (twoFactor.value && this.user.twofactor_habilitated) {
          const response = await this.handleTwoFactorModal(
            this.user.twofactor_habilitated
          )
          if (response == 'reset') {
            this.$dialog.message.error(this.$t('twofactor_required'))
            this.$router.push({
              name: 'Delete',
              query: {
                redirect: 'retiro-de-fondos/create'
              }
            })
            throw new Error(this.$t('twofactor_required'))
          }
          if (!response) return
        }
        this.loading = true
        const payload = {
          language: this.idiom.code,
          fundType: 'cash',
          operationType: this.operation,
          transferInstructionId: this.clientRequest.permanentInstruction
            .transferInstructionId,
          amount: parseFloat(this.clientRequest.amount),
          currency: this.clientRequest.currency.currencyName,
          currencyCode: this.clientRequest.currency.currencyCode,
          operationCurrencyCode: this.clientRequest.dollarFunds
            ? 'USD'
            : this.clientRequest.currency.currencyCode,
          description: '',
          avsInternalRemark: this.clientRequest.permanentInstruction
            .avsInternalRemark,
          bnfReference: this.clientRequest.permanentInstruction.bnfReference,
          schedule: {
            recurrent: this.clientRequest.schedule.recurrent,
            monthlyFrequency: parseInt(
              this.clientRequest.schedule.monthlyFrequency
            ),
            day: parseInt(this.clientRequest.schedule.day),
            start: this.clientRequest.schedule.start,
            end: this.clientRequest.schedule.end
          }
        }

        if (payload.schedule.end === '') delete payload.schedule.end
        if (payload.schedule.monthlyFrequency === '')
          delete payload.schedule.monthlyFrequency
        if (payload.schedule.day === '') delete payload.schedule.day

        // Send Authorization File when is Executive
        if (this.clientRequest.authorizationFile) {
          payload.authorizationFile = this.clientRequest.authorizationFile
        }

        if (this.isExecutive) {
          payload.isExecutive = true
        }

        const request = await ClientTransferRequestService.post(
          this.account.code,
          payload
        )

        if (request.status == 200) {
          this.$dialog.notify.success(this.$t('transfer_request_success'))
          this.$router.push({
            name: this.operation == 'W' ? 'Retire' : 'Deposit'
          })
          this.clearState()
        } else {
          this.$dialog.notify.error(this.$t('transfer_request_error'))
        }
      } catch (error) {
        console.log(error)
        this.$dialog.notify.error(this.$t('transfer_request_error'))
      } finally {
        this.loading = false
      }
    },
    handleCancel() {
      this.$router.go(-1)
    },
    handleClear() {},
    async getCurrencies() {
      this.loading = true
      try {
        const { data } = await CurrencyService.fetch()
        this.currencies = data
      } catch (error) {
        if (error?.response?.status !== 401)
          this.$dialog.message.error('No se pudo obtener el listado de monedas.')
      } finally {
        this.loading = false
      }
    },
    async getCashAvailable() {
      this.loading = true
      try {
        const { data } = await CashAvailableService.myCash(this.account.code)
        this.cashAvailable = data.cashAvailable
      } catch (error) {
        if (error?.response?.status !== 401)
          this.$dialog.message.error('No se pudieron obtener los fondos disponibles.')
      } finally {
        this.loading = false
      }
    },
    handleInstruction(instruction) {
      this.clientRequest.permanentInstruction = instruction
    },
    clearInstruction() {
      this.clientRequest.permanentInstruction = {}
    },
    clearSchedule() {
      this.clientRequest.schedule.monthlyFrequency = 0
      this.clientRequest.schedule.day = 1
      this.clientRequest.schedule.end = ''
    },
    handleNext(step) {
      if (step == 1) {
        if (
          this.clientRequest.instructionType ==
            'transfer_request_existing_instruction' ||
          this.clientRequest.permanentInstruction.transferInstructionId
        ) {
          return step + 1
        } else {
          const clientRequestData = {
            operationType: this.operation,
            currency: this.clientRequest.currency,
            amount: this.clientRequest.amount,
            schedule: {
              start: this.clientRequest.schedule.start
            }
          }
          this.setClientRequest(clientRequestData)
          this.setTransferRedirect(true)
          return null
        }
      } else {
        return step + 1
      }
    },
    async handleTwoFactorModal(twofactor) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: twofactor ? '40%' : '80%',
        transition: 'dialog-top-transition',
        persistent: false,
        hideOverlay: false,
        twoFactor: twofactor,
        hideSubmit: true,
        showClose: twofactor
      }
      let response = await this.$dialog.showAndWait(TwoFactorModal, params)
      return response
    }
  }
}
</script>
<style scoped>
.container {
  max-width: 1264px;
}
</style>
