import BaseService from './base'
import axios from '@/plugins/axios'

export default class ClientTransferSchedulesService extends BaseService {
  static resource() {
    return 'api/v1/external/clientTransferSchedules'
  }

  static async find(accountCode) {
    const { data } = await axios.get(
      `${this.baseURL()}/${this.resource()}/${accountCode}`
    )
    return data
  }

  static async update(accountCode, transferScheduleId, payload = {}) {
    const { data } = await axios.patch(
      `${this.baseURL()}/${this.resource()}/${accountCode}/${transferScheduleId}`,
      payload
    )
    return data
  }

  static async delete(accountCode, transferScheduleId) {
    const { data } = await axios.delete(
      `${this.baseURL()}/${this.resource()}/${accountCode}/${transferScheduleId}`
    )
    return data
  }
}
