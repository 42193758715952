<template>
  <wizard-form v-on="$listeners" v-bind="$attrs">
    <template #content>
      <v-row dense>
        <v-col cols="12">
          <div>
            <h1 class="primary--text  font-weight-bold">
              {{ operation == 'W' ? $t('transfer_request_withdrawal') : $t('transfer_request_deposit') }}
            </h1>
            <div class="mt-2 mb-4">
              <hr class="rounded primary" color="primary"/>
              <hr class="primary" size="1" color="primary" />
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <ValidationProvider
            :name="$t('transfer_available_funds')"
            rules="required"
            v-slot="{ errors }"
          >
            <numeric-input
              :label="$t('transfer_available_funds')"
              dense
              autocomplete="nope"
              v-model="cashAvailable"
              :error-messages="errors[0]"
              readonly
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_request_currency')"
            rules="required"
            v-slot="{ errors }"
          >
            <v-select
              v-model="clientRequest.currency"
              :items="currencies"
              :label="`${$t('transfer_request_currency')} *`"
              :error-messages="errors[0]"
              dense
              return-object
              item-text="currencyName"
            >
            </v-select>
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_request_amount')"
            rules="required|min_value:0.01"
            v-slot="{ errors }"
          >
            <numeric-input
              :label="`${$t('transfer_request_amount')} *`"
              dense
              autocomplete="nope"
              v-model="clientRequest.amount"
              :error-messages="errors[0]"
              maxlength="50"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_request_start_date')"
            rules="required"
            v-slot="{ errors }"
          >
            <date-picker-input
              :label="`${$t('transfer_request_start_date')} *`"
              v-model="clientRequest.schedule.start"
              dense
              :min="minDate"
              :allowedDates="allowedDates"
              :error-messages="errors[0]"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row v-if="clientRequest.currency && clientRequest.currency.currencyCode !== 'USD'" dense>
        <v-col cols="12">
          <h3>{{ $t('transfer_request_base_currency') }}</h3>
          <ValidationProvider
            :name="$t('transfer_request_usd_funds')"
            rules="required"
            v-slot="{ errors }"
          >
            <v-radio-group 
              v-model="clientRequest.dollarFunds"
              :error-messages="errors[0]"
            >
              <v-radio
                :label="$t('transfer_request_usd_funds')"
                :value="true"
              ></v-radio>
              <v-radio
                :label="$t('transfer_request_currency_funds')"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row v-if="!clientRequest.permanentInstruction.fromIndex" dense>
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_request_instruction')"
            rules="required"
            v-slot="{ errors }"
          >
            <v-select
              v-model="clientRequest.instructionType"
              :items="options"
              :label="`${$t('transfer_request_instruction')} *`"
              dense
              :error-messages="errors[0]"
            >
              <template #item="{ item }">
                <span>{{ $t(item) }}</span>
              </template>

              <template #selection="{ item }">
                <span>{{ $t(item) }}</span>
              </template>
            </v-select>
          </ValidationProvider>
        </v-col>
      </v-row>
    </template> 
  </wizard-form>
</template>
<script>
import WizardForm from '@/components/commons/WizardForm'
import DatePickerInput from '@/components/commons/DatePickerInput'
import NumericInput from '@/components/commons/NumericInput'

export default {
  components: {
    WizardForm,
    DatePickerInput,
    NumericInput
  },
  props: {
    clientRequest: {
      type: Object,
      require: true
    },
    cashAvailable: {
      type: Number,
      require: true
    },
    currencies: {
      type: Array,
      require: true
    },
    operation: {
      type: String,
      require: true
    },
    instructions: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      stepTwo: {
        account_type: 'Cuentas de Bancos en Panamá'
      },
      items: [
        'transfer_request_existing_instruction',
        'transfer_request_new_instruction'
      ],
      banks: ['Banco Mercantil', 'Banesco']
    }
  },
  computed: {
    options() {
      return this.instructions.length ? this.items : this.items.filter((i) => i !== 'transfer_request_existing_instruction')
    },
    minDate() {
      return this.$date().format('YYYY-MM-DD')
    },
    allowedDates() {
      return val => this.getDay(val) !== 5 && this.getDay(val) !== 6
    }
  },
  methods: {
    getDay(date) {
      return new Date(date).getDay()
    }
  }
}
</script>
<style scoped>
.main-container {
  line-height: normal;
}

hr {
  margin: 0;
  padding: 0;
}

hr.rounded {
  border-top: 2px solid;
  width: 400px;
}
</style>