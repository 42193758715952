<template>
  <v-card flat>
    <v-card-text>
      <slot name="content"></slot>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <slot name="before-actions"></slot>
      <v-row>
        <v-col cols="12" class="d-flex justify-space-between justify-md-end">
          <v-btn @click="$emit('cancel', $event)">{{ $t('btn_cancel') }}</v-btn>
          <v-spacer />

          <v-btn
            v-if="canPrevious"
            class="mx-2"
            @click="$emit('previous', $event)"
            >{{ $t('btn_previous') }}</v-btn
          >
          <v-btn
            v-if="canNext"
            class="mx-2"
            color="primary"
            :disabled="invalid || disabledNext"
            @click="$emit('next', $event)"
          >
            {{ $t('btn_next') }}
          </v-btn>

          <v-btn
            v-if="canSubmit"
            class="mx-2"
            color="primary"
            :disabled="invalid || disabledNext"
            @click="$emit('submit', $event)"
          >
            {{ $t('btn_save') }}
          </v-btn>
        </v-col>
      </v-row>
      <slot name="after-actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    invalid: {
      type: Boolean,
      default: false
    },
    canPrevious: {
      type: Boolean,
      required: true
    },
    canNext: {
      type: Boolean,
      required: true
    },
    canSubmit: {
      type: Boolean,
      default: false
    },
    disabledNext: {
      type: Boolean,
      default: false
    },

  }
}
</script>

<style></style>
