<template>
  <div class="d-flex justify-center flex-column">
    <v-card
      tile
      class="px-8 pt-4 pb-6"
      :width="$vuetify.breakpoint.smAndDown ? '100%' : width"
    >
      <!-- <v-card-title
      class="primary white--text font-weight-bold title"
      v-text="title"
      />-->

      <!-- <v-card-title class="font-weight-bold title ml-3" v-text="title" /> -->
      <!-- <v-divider /> -->

      <v-row justify="space-between" class="mb-8">
        <v-col cols="12" md="6" class="text-h6 font-weight-bold mt-6">
          {{ title }}
        </v-col>
      </v-row>
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <form @submit.prevent="$emit('submit', $event)">
          <v-card-text>
            <v-container fluid grid-list-xl pa-md-4>
              <template v-if="loading">
                <Loading :loading="loading" />
              </template>
              <template v-else>
                <slot name="content"></slot>
              </template>
            </v-container>
          </v-card-text>

          <!-- CARD FOOTER -->
          <v-divider />
          <v-card-actions class="d-flex justify-space-between justify-sm-end">
            <slot name="footer-custom-button-before"></slot>
            <v-btn
              class="mx-2 cancelar-button"
              x-large
              @click="$router.go(-1)"
              rounded
              >Cancelar</v-btn
            >
            <v-btn
              class="mx-2 submit-button"
              color="primary"
              type="submit"
              rounded
              x-large
              :loading="loading"
              :disabled="invalid || loading"
            >
              {{ textSave }}
              <!-- <v-icon class="ml-2" v-text="iconSave" /> -->
            </v-btn>
            <slot name="footer-custom-button-after"></slot>
          </v-card-actions>
        </form>
      </ValidationObserver>
    </v-card>
  </div>
</template>

<script>
import Loading from '../commons/Loading'
export default {
  name: 'BaseForm',
  components: { Loading },
  props: {
    title: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    saving: {
      type: Boolean,
      default: false
    },
    textSave: {
      type: String,
      default: 'Registrar'
    },
    iconSave: {
      type: String,
      default: 'mdi-send'
    },
    width: {
      type: [String, Number],
      default: '100%'
    }
  }
}
</script>

<style>
.submit-button {
  width: 208px;
  background: transparent linear-gradient(112deg, #00559e 0%, #0090df 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 6px 10px 14px #00000029;
  border-radius: 28px;
  opacity: 1;
}
.cancelar-button {
  width: 208px;
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #00559e !important;
  box-shadow: 6px 10px 14px #00000029;
  border: 1px solid #00559e;
  border-radius: 28px;
}
</style>
