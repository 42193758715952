<template>
  <base-form-modal
    :title="title"
    :loading="loading"
    @close="$emit('submit', null)"
    @submit="handleSubmit"
  >
    <template #content>
      <v-row class="my-2">
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_request_monthly_frequency')"
            rules="required|min_value:1|max_value:12|max:50|numeric"
            v-slot="{ errors }"
          >
            <v-text-field
              :label="`${$t('transfer_request_monthly_frequency')}`"
              dense
              autocomplete="nope"
              v-model="clientRequest.schedule.monthlyFrequency"
              :error-messages="errors[0]"
              maxlength="50"
              v-mask="'##'"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_request_business_day')"
            rules="required|max:50|min_value:1|max_value:31"
            v-slot="{ errors }"
          >
            <v-text-field
              :label="`${$t('transfer_request_business_day')}`"
              dense
              autocomplete="nope"
              v-model="clientRequest.schedule.day"
              :error-messages="errors[0]"
              maxlength="50"
              v-mask="'##'"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_request_recurrent_start_date')"
            rules="required|max:50"
            v-slot="{ errors }"
          >
            <date-picker-input
              :label="`${$t('transfer_request_recurrent_start_date')}`"
              v-model="clientRequest.schedule.start"
              dense
              :min="minDate"
              :error-messages="errors[0]"
              @input="handleStartDate"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_request_end_day')"
            rules="required|max:50"
            v-slot="{ errors }"
          >
            <date-picker-input
              :label="`${$t('transfer_request_end_day')}`"
              v-model="clientRequest.schedule.end"
              dense
              :min="clientRequest.schedule.start"
              :error-messages="errors[0]"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
    </template>
  </base-form-modal>
</template>

<script>
import BaseFormModal from '@/components/commons/BaseFormModal'
import DatePickerInput from '@/components/commons/DatePickerInput'
import ClientTransferSchedulesService from '@/services/clientTransferSchedules'

export default {
  components: { BaseFormModal, DatePickerInput },
  props: {
    clientRequest: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    minDate() {
      return this.$date()
        .add(1, 'day')
        .format('YYYY-MM-DD')
    },
    title() {
      return (
        this.$t('transfer_request_recurrent_edit') +
        ' - ' +
        this.clientRequest.data.avsInternalRemark
      )
    }
  },
  methods: {
    handleStartDate() {
      const isBefore = this.$date(this.clientRequest.schedule.end).isBefore(
        this.clientRequest.schedule.start
      )
      if (isBefore) {
        this.clientRequest.schedule.end = this.clientRequest.schedule.start
      }
    },
    async handleSubmit() {
      const res = await this.$dialog.warning({
        title: this.$t('general_confirmation_label'),
        text: this.$t('general_save_question'),
        actions: {
          false: {
            text: 'No'
          },
          true: {
            text: this.$t('btn_yes'),
            color: 'blue'
          }
        }
      })

      if (res) {
        this.loading = true
        const payload = {
          ...this.clientRequest.schedule,
          day: parseInt(this.clientRequest.schedule.day),
          monthlyFrequency: parseInt(
            this.clientRequest.schedule.monthlyFrequency
          )
        }

        delete payload.isEnabled

        try {
          await ClientTransferSchedulesService.update(
            this.clientRequest.accountCode,
            this.clientRequest.transferScheduleId,
            payload
          )
          this.$emit('submit', true)
        } catch (error) {
          console.log(error)
          this.$dialog.message.error('No se pudo guardar la información.')
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
