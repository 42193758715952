var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-form-modal',{attrs:{"title":_vm.title,"loading":_vm.loading},on:{"close":function($event){return _vm.$emit('submit', null)},"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',{staticClass:"my-2"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_request_monthly_frequency'),"rules":"required|min_value:1|max_value:12|max:50|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"label":("" + (_vm.$t('transfer_request_monthly_frequency'))),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.clientRequest.schedule.monthlyFrequency),callback:function ($$v) {_vm.$set(_vm.clientRequest.schedule, "monthlyFrequency", $$v)},expression:"clientRequest.schedule.monthlyFrequency"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_request_business_day'),"rules":"required|max:50|min_value:1|max_value:31"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"label":("" + (_vm.$t('transfer_request_business_day'))),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.clientRequest.schedule.day),callback:function ($$v) {_vm.$set(_vm.clientRequest.schedule, "day", $$v)},expression:"clientRequest.schedule.day"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_request_recurrent_start_date'),"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker-input',{attrs:{"label":("" + (_vm.$t('transfer_request_recurrent_start_date'))),"dense":"","min":_vm.minDate,"error-messages":errors[0]},on:{"input":_vm.handleStartDate},model:{value:(_vm.clientRequest.schedule.start),callback:function ($$v) {_vm.$set(_vm.clientRequest.schedule, "start", $$v)},expression:"clientRequest.schedule.start"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_request_end_day'),"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker-input',{attrs:{"label":("" + (_vm.$t('transfer_request_end_day'))),"dense":"","min":_vm.clientRequest.schedule.start,"error-messages":errors[0]},model:{value:(_vm.clientRequest.schedule.end),callback:function ($$v) {_vm.$set(_vm.clientRequest.schedule, "end", $$v)},expression:"clientRequest.schedule.end"}})]}}])})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }