<template>
  <v-menu
    ref="menu"
    v-model="menu"
    v-bind="$attrs"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :append-icon="appendIcon"
        v-bind="$attrs"
        v-on="on"
        :value="date"
        autocomplete="off"
        @click:append="menu = true"
        @input="$emit('input', $event)"
        readonly
      />
    </template>
    <v-date-picker
      :value="date"
      :min="min"
      :max="max"
      :allowed-dates="allowedDates"
      no-title
      locale="en"
      @input="handleInput"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'DatePickerInput',
  props: {
    value: {
      type: [Date, String, Number],
      default: ''
    },
    appendIcon: {
      type: String,
      default: 'mdi-calendar'
    },
    min: {
      type: String
    },
    max: {
      type: String
    },
    allowedDates: {
      type: [Date, String, Number, Function],
    },
  },
  data() {
    return {
      menu: false
    }
  },
  computed: {
    date() {
      return this.value
    }
  },
  methods: {
    handleInput(value) {
      this.menu = false
      this.$emit('input', value)
    }
  }
}
</script>

<style></style>
