<template>
  <v-text-field
    v-bind="$attrs"
    :prefix="currency"
    :value="amount"
    @input="handleInput"
    ref="field"
  >
  </v-text-field>
</template>

<script>
import AutoNumeric from 'autonumeric'

export default {
  name: 'NumericInput',
  props: {
    value: {
      type: [String, Number],
      default: 0.0
    },
    currency: {
      type: String,
      default: ''
    },
    options: {
      type: [Object, Array, String],
      default: () => {
        return {
          minimumValue: 0,
          digitGroupSeparator: '.',
          decimalCharacter: ',',
          decimalCharacterAlternative: '.'
        }
      }
    }
  },
  mounted() {
    // Instanciamos la clase AutoNumeric
    this.input = new AutoNumeric(this.$refs.field.$refs.input, this.options)

    // Evento para actualizar el valor visual en el campo
    this.$refs.field.$refs.input.addEventListener(
      'autoNumeric:formatted',
      this.updateValue
    )

    // Valor Inicial
    this.input.set(this.value)
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== this.input.getNumber() && newVal !== oldVal) {
        this.input.set(newVal)
      }
    }
  },
  data: () => {
    return {
      input: null,
      amount: 0
    }
  },
  methods: {
    updateValue() {
      this.amount = this.input.getFormatted()
    },
    handleInput() {
      this.$emit('input', this.input.getNumber())
    }
  }
}
</script>
