var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('wizard-form',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('h1',{staticClass:"primary--text  font-weight-bold"},[_vm._v(" "+_vm._s(_vm.operation == 'W' ? _vm.$t('transfer_request_withdrawal') : _vm.$t('transfer_request_deposit'))+" ")]),_c('div',{staticClass:"mt-2 mb-4"},[_c('hr',{staticClass:"rounded primary",attrs:{"color":"primary"}}),_c('hr',{staticClass:"primary",attrs:{"size":"1","color":"primary"}})])])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_available_funds'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('numeric-input',{attrs:{"label":_vm.$t('transfer_available_funds'),"dense":"","autocomplete":"nope","error-messages":errors[0],"readonly":""},model:{value:(_vm.cashAvailable),callback:function ($$v) {_vm.cashAvailable=$$v},expression:"cashAvailable"}})]}}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_request_currency'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.currencies,"label":((_vm.$t('transfer_request_currency')) + " *"),"error-messages":errors[0],"dense":"","return-object":"","item-text":"currencyName"},model:{value:(_vm.clientRequest.currency),callback:function ($$v) {_vm.$set(_vm.clientRequest, "currency", $$v)},expression:"clientRequest.currency"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_request_amount'),"rules":"required|min_value:0.01"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('numeric-input',{attrs:{"label":((_vm.$t('transfer_request_amount')) + " *"),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.clientRequest.amount),callback:function ($$v) {_vm.$set(_vm.clientRequest, "amount", $$v)},expression:"clientRequest.amount"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_request_start_date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker-input',{attrs:{"label":((_vm.$t('transfer_request_start_date')) + " *"),"dense":"","min":_vm.minDate,"allowedDates":_vm.allowedDates,"error-messages":errors[0]},model:{value:(_vm.clientRequest.schedule.start),callback:function ($$v) {_vm.$set(_vm.clientRequest.schedule, "start", $$v)},expression:"clientRequest.schedule.start"}})]}}])})],1)],1),(_vm.clientRequest.currency && _vm.clientRequest.currency.currencyCode !== 'USD')?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('transfer_request_base_currency')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_request_usd_funds'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors[0]},model:{value:(_vm.clientRequest.dollarFunds),callback:function ($$v) {_vm.$set(_vm.clientRequest, "dollarFunds", $$v)},expression:"clientRequest.dollarFunds"}},[_c('v-radio',{attrs:{"label":_vm.$t('transfer_request_usd_funds'),"value":true}}),_c('v-radio',{attrs:{"label":_vm.$t('transfer_request_currency_funds'),"value":false}})],1)]}}],null,false,2360819135)})],1)],1):_vm._e(),(!_vm.clientRequest.permanentInstruction.fromIndex)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_request_instruction'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.options,"label":((_vm.$t('transfer_request_instruction')) + " *"),"dense":"","error-messages":errors[0]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t(item)))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t(item)))])]}}],null,true),model:{value:(_vm.clientRequest.instructionType),callback:function ($$v) {_vm.$set(_vm.clientRequest, "instructionType", $$v)},expression:"clientRequest.instructionType"}})]}}],null,false,2479485057)})],1)],1):_vm._e()]},proxy:true}])},'wizard-form',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }