import BaseService from './base'
import axios from '@/plugins/axios'

export default class CashAvailableService extends BaseService {
  static resource() {
    return 'api/v1/external/cashAvailable'
  }

  static async myCash(accountCode) {
    const { data } = await axios.get(
      `${this.baseURL()}/${this.resource()}/${accountCode}`
    )
    return data
  }
}
