<template>
  <v-container>
    <BaseList
      flat
      color="primary"
      :title="$t('transfer_request_actives_operations_withdrawal')"
      :section="$route.meta.section"
      :headers="headers"
      :items="actives"
      :loading="loading"
      :totalRows="totalRows"
      :breadcrumbs="false"
      :elevation="0"
      :height="'100%'"
      :buttons="{search: true, add: !handleAuthorityAccounts() || isExecutive}"
      @add="handleNew"
      @refresh="getData"
      @options="handleOptions"
      @search="handleSearch"
      :search="search"
    >
      <template #header.fundType>
        <span> {{$t('transfer_request_instrument_type')}} </span>
      </template>
      <template #header.operationType>
        <span> {{$t('transfer_request_operation_type')}} </span>
      </template>
      <template #header.alias>
        <span> {{$t('transfer_index_alias')}} </span>
      </template>
      <template #header.currency>
        <span> {{$t('transfer_request_currency')}} </span>
      </template>
      <template #header.amount>
        <span> {{$t('transfer_request_amount')}} </span>
      </template>
      <template #header.schedule.start>
        <span> {{$t('transfer_request_start_date')}} </span>
      </template>
      <template #header.bnfReference>
        <span> {{$t('transfer_request_observations')}} </span>
      </template>
      <template #header.status>
        <span> {{$t('transfer_request_status')}} </span>
      </template>

      <template #item.operationType="{ value }">
        <span>{{ value == 'W' ? $t('transfer_request_type_withdrawal') : $t('transfer_request_type_deposit') }}</span>
      </template>
    </BaseList>
  </v-container>
</template>

<script>
import BaseList from '@/components/commons/BaseList'
import ClientTransferRequestService from '@/services/clientTransferRequest'
import { mapActions, mapState } from 'vuex'
export default {
  components: { BaseList },
  data() {
    return {
      options: {},
      dialog: false,
      loading: false,
      totalRows: 0,
      actives: [],
      search: '',
      certification: {},
      headers: [
        { text: 'Alias', value: 'alias' },
        { text: 'Código de Cliente', value: 'fundType' },
        { text: 'Nombre Cliente', value: 'operationType' },
        { text: 'Moneda', value: 'currency' },
        { text: 'Monto Solicitado', value: 'amount' },
        { text: 'Fecha', value: 'schedule.start' },
        { text: 'Referencia', value: 'bnfReference' },
        { text: 'Estatus', value: 'status' }
      ]
    }
  },
  async created() {
    await this.getData()
  },
  computed: {
    ...mapState('account', ['account', 'authorityAccounts']),
    ...mapState('auth', ['user']),
    isExecutive() {
      return this.user?.authorityLevelsCode === 1
    }
  },
  methods: {
    ...mapActions('transfer', ['setPermanentInstruction']),
    async getData() {
      this.loading = true
      try {
        let data = await ClientTransferRequestService.find(this.account.code)
        this.actives = data.filter(
          item => item.operationType == 'W' && item.isActive
        )
        this.totalRows = this.actives.length
      } catch (error) {
        if (error.response.status !== 401)
          this.$dialog.message.error('No se pudo cargar la información.')
      } finally {
        this.loading = false
      }
    },
    handleOptions(options) {
      this.options = options
      this.getData()
    },
    handleNew() {
      this.setPermanentInstruction({})
      this.$router.push({ name: 'RetireCreate' })
    },
    handleSearch(search) {
      this.search = search
    },
    handleAuthorityAccounts() {
      return this.authorityAccounts.findIndex(item => item.code == this.account.code) > -1
    }
  }
}
</script>

<style scoped>
.v-link-dialog {
  text-align: left;
  font: normal normal bold 14px/17px Lato;
  letter-spacing: 0px;
  color: #00559e;
  opacity: 1;
}
.login-button {
  width: 208px;
  background: transparent linear-gradient(112deg, #00559e 0%, #0090df 100%) 0%
    0% no-repeat padding-box;
  color: white !important;
  box-shadow: 6px 10px 14px #00000029;
  border-radius: 28px;
  opacity: 1;
}

.v-title-card {
  text-align: left;
  font: normal normal bold 16px/38px Lato;
  letter-spacing: 0px;
  color: #212529;
  opacity: 1;
}
</style>
