<template>
  <wizard-form v-on="$listeners" v-bind="$attrs">
    <template #content>
      <v-row>
        <v-col cols="12">
          <div>
            <h1 class="primary--text  font-weight-bold">
              {{ $t('transfer_summary') }}
            </h1>
            <div class="mt-2">
              <hr class="rounded primary" color="primary"/>
              <hr class="primary" size="1" color="primary" />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="clientRequest && !isObjectEmpty(clientRequest.permanentInstruction)"
        dense
        class="mb-4"
      >
        <v-col cols="12">
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold">
                      {{ $t('transfer_request_operation') }}
                    </v-list-item-title>
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon small class="mr-sm-5 d-none d-sm-flex" v-text="'mdi-chevron-right'" color="blue darken-4"/>
                    <v-list-item-subtitle class="ml-sm-5">
                      {{ operation == 'W' ? $t('transfer_request_operation_withdrawal') : $t('transfer_request_operation_deposit') }}
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="blue lighten-4"></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold">
                      {{ $t('transfer_name') }}
                    </v-list-item-title>
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon small class="mr-sm-5 d-none d-sm-flex" v-text="'mdi-chevron-right'" color="blue darken-4"/>
                    <v-list-item-subtitle class="ml-sm-5">
                      {{ clientRequest.permanentInstruction.beneficiary }}
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="blue lighten-4"></v-divider>
            <v-list-item v-if="clientRequest.permanentInstruction.accountType !== 'avsInternal'">
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold">
                      {{ $t('transfer_beneficiary_data') }}
                    </v-list-item-title>
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon small class="mr-sm-5 d-none d-sm-flex" v-text="'mdi-chevron-right'" color="blue darken-4"/>
                    <v-list-item-subtitle class="ml-sm-5">
                      <span v-if="clientRequest.permanentInstruction.bnfEmail">{{ clientRequest.permanentInstruction.bnfEmail}}</span>
                      <span v-if="clientRequest.permanentInstruction.bnfPhone">{{ clientRequest.permanentInstruction.bnfPhone}} </span> 
                      <span v-if="clientRequest.permanentInstruction.bnfAddres1">{{ clientRequest.permanentInstruction.bnfAddres1 }} {{ clientRequest.permanentInstruction.bnfAddres2 }} - </span> 
                      <span v-if="clientRequest.permanentInstruction.bnfCountry">{{ clientRequest.permanentInstruction.bnfCountry }} {{ clientRequest.permanentInstruction.bnfState }} {{ clientRequest.permanentInstruction.bnfCity }}, {{ clientRequest.permanentInstruction.bnfZipcode }}</span>
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="clientRequest.permanentInstruction.accountType !== 'avsInternal'" class="blue lighten-4"></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold">
                      {{ $t('transfer_account_type') }}
                    </v-list-item-title>
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon small class="mr-sm-5 d-none d-sm-flex" v-text="'mdi-chevron-right'" color="blue darken-4"/>
                    <v-list-item-subtitle class="ml-sm-5">{{
                      $t(handleAccountType(clientRequest.permanentInstruction.accountType))
                    }}</v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="blue lighten-4"></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold">
                      {{ $t('transfer_financial_institution_of_beneficiary') }}
                    </v-list-item-title>
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon small class="mr-sm-5 d-none d-sm-flex" v-text="'mdi-chevron-right'" color="blue darken-4"/>
                    <v-list-item-subtitle class="ml-sm-5">
                      {{ clientRequest.permanentInstruction.bnfBankName }} - {{ clientRequest.permanentInstruction.bnfBankAccNumber }}
                      <span v-if="clientRequest.permanentInstruction.accountType !== 'nfiAccount'">
                        <span v-if="clientRequest.permanentInstruction.bnfBankABA">ABA/SWIFT {{ clientRequest.permanentInstruction.bnfBankABA }} </span>
                        <span v-if="clientRequest.permanentInstruction.bnfBankAddres1">- {{ clientRequest.permanentInstruction.bnfBankAddres1 }} {{ clientRequest.permanentInstruction.bnfBankAddres2 }}</span>
                        <span v-if="clientRequest.permanentInstruction.bnfBankCountry"> {{ clientRequest.permanentInstruction.bnfBankCountry }} {{ clientRequest.permanentInstruction.bnfBankState }} {{ clientRequest.permanentInstruction.bnfBankCity }}</span>
                      </span>
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="blue lighten-4"></v-divider>
            <v-list-item v-if="clientRequest.permanentInstruction.nfiName">
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold">
                      {{ $t('transfer_receiving_bank') }}
                    </v-list-item-title>
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon
                      small
                      class="mr-sm-5 d-none d-sm-flex"
                      v-text="'mdi-chevron-right'"
                      color="blue darken-4"
                    />
                    <v-list-item-subtitle class="ml-sm-5">
                      {{ clientRequest.permanentInstruction.nfiName }} - {{ clientRequest.permanentInstruction.nfiAccountNumber }}
                      <span v-if="clientRequest.permanentInstruction.bnfBankABA">- ABA/SWIFT {{ clientRequest.permanentInstruction.bnfBankABA }} -</span> {{ clientRequest.permanentInstruction.bnfBankCity }} {{ clientRequest.permanentInstruction.bnfBankCountry }}
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              v-if="clientRequest.permanentInstruction.nfiName"
              class="blue lighten-4"
            ></v-divider>
            <v-list-item
              v-if="clientRequest.permanentInstruction.intermBankIsUsed"
            >
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold">
                      {{ $t('transfer_intermediary') }}
                    </v-list-item-title>
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon
                      small
                      class="mr-sm-5 d-none d-sm-flex"
                      v-text="'mdi-chevron-right'"
                      color="blue darken-4"
                    />
                    <v-list-item-subtitle class="ml-sm-5">
                      {{ clientRequest.permanentInstruction.intermBankName }} - {{ clientRequest.permanentInstruction.intermBankAccNumber }} 
                      <span v-if="clientRequest.permanentInstruction.intermBankABA">ABA/SWIFT {{ clientRequest.permanentInstruction.intermBankABA }} </span>
                      <span v-if="clientRequest.permanentInstruction.intermBankAddres1">- {{ clientRequest.permanentInstruction.intermBankAddres1 }} {{ clientRequest.permanentInstruction.intermBankAddres2 }}</span>
                      <span v-if="clientRequest.permanentInstruction.intermBankCountry"> {{ clientRequest.permanentInstruction.intermBankCountry }} {{ clientRequest.permanentInstruction.intermBankState }} {{ clientRequest.permanentInstruction.intermBankCity }}</span>
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              v-if="clientRequest.permanentInstruction.intermBankIsUsed"
              class="blue lighten-4"
            ></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold">
                      {{ $t('transfer_request_amount') }}
                    </v-list-item-title>
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon
                      small
                      class="mr-sm-5 d-none d-sm-flex"
                      v-text="'mdi-chevron-right'"
                      color="blue darken-4"
                    />
                    <v-list-item-subtitle class="ml-sm-5">
                      {{ clientRequest.amount }} {{ clientRequest.currency.currencyCode }}
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="blue lighten-4"></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold">
                      {{ $t('transfer_request_start_date') }}
                    </v-list-item-title>
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon
                      small
                      class="mr-sm-5 d-none d-sm-flex"
                      v-text="'mdi-chevron-right'"
                      color="blue darken-4"
                    />
                    <v-list-item-subtitle class="ml-sm-5">
                      {{ clientRequest.schedule.start }}
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="blue lighten-4"></v-divider>
            <v-list-item v-if="clientRequest.schedule.recurrent">
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold">
                      {{ $t('transfer_request_recurrence') }}
                    </v-list-item-title>
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon
                      small
                      class="mr-sm-5 d-none d-sm-flex"
                      v-text="'mdi-chevron-right'"
                      color="blue darken-4"
                    />
                    <v-list-item-subtitle class="ml-sm-5">
                      <span>{{ $t('transfer_request_monthly_frequency') }}: {{ clientRequest.schedule.monthlyFrequency }}</span>  -
                      <span>{{ $t('transfer_request_business_day') }}: {{ clientRequest.schedule.day }}</span>  -
                      <span>{{ $t('transfer_request_end_day') }}: {{ clientRequest.schedule.end }}</span>
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="clientRequest.schedule.recurrent" class="blue lighten-4"></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold">
                      {{ $t('transfer_observations') }}
                    </v-list-item-title>
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon
                      small
                      class="mr-sm-5 d-none d-sm-flex"
                      v-text="'mdi-chevron-right'"
                      color="blue darken-4"
                    />
                    <v-list-item-subtitle class="ml-sm-5">
                      {{ clientRequest.permanentInstruction.bnfReference }}
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="blue lighten-4"></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold">
                      {{ $t('transfer_instructions') }}
                    </v-list-item-title>
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon
                      small
                      class="mr-sm-5 d-none d-sm-flex"
                      v-text="'mdi-chevron-right'"
                      color="blue darken-4"
                    />
                    <v-list-item-subtitle class="ml-sm-5">{{
                      clientRequest.permanentInstruction.avsInternalRemark
                    }}</v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="blue lighten-4"></v-divider>
          </v-list>
        </v-col>
      </v-row>
    </template>
  </wizard-form>
</template>
<script>
import WizardForm from '@/components/commons/WizardForm'
import { isObjectEmpty } from '@/utils'

export default {
  components: {
    WizardForm
  },
  props: {
    clientRequest: {
      type: [Object],
      required: true
    },
    operation: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      accountTypes: [
        { value: 'localCheckingAccount', t: 'transfer_account_type_1' },
        { value: 'localSavingsAccount', t: 'transfer_account_type_2' },
        { value: 'nfiAccount', t: 'transfer_account_type_3' },
        { value: 'intlBankAccount', t: 'transfer_account_type_4' },
        { value: 'avsInternal', t: 'transfer_internal_account' }
      ]
    }
  },
  methods: {
    isObjectEmpty(objectName) {
      return isObjectEmpty(objectName)
    },
    handleAccountType(accountType) {
      const type = this.accountTypes.find(item => item.value == accountType)
      return type?.t
    }
  }
}
</script>
<style scoped>
.main-container {
  line-height: normal;
}

hr {
  margin: 0;
  padding: 0;
}

hr.rounded {
  border-top: 2px solid;
  width: 250px;
}
</style>
