<template>
  <wizard-form v-on="$listeners" v-bind="$attrs">
    <template #content>
      <v-row dense>
        <v-col cols="12">
          <div>
            <h1 class="primary--text  font-weight-bold">
              {{ $t('transfer_request_recurrence') }}
            </h1>
            <div class="mt-2 mb-4">
              <hr class="rounded primary" color="primary" />
              <hr class="primary" size="1" color="primary" />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-radio-group
            v-model="clientRequest.schedule.recurrent"
            @change="handleRadio"
          >
            <v-radio
              :label="$t('transfer_request_non_recurring')"
              :value="false"
            ></v-radio>
            <v-radio
              :label="$t('transfer_request_recurrent')"
              :value="true"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row v-if="clientRequest.schedule.recurrent" dense>
        <v-col cols="12" md="4">
          <ValidationProvider
            :name="$t('transfer_request_monthly_frequency')"
            rules="required|min_value:1|max_value:12|max:50|numeric"
            v-slot="{ errors }"
          >
            <v-text-field
              :label="`${$t('transfer_request_monthly_frequency')} *`"
              dense
              autocomplete="nope"
              v-model="clientRequest.schedule.monthlyFrequency"
              :error-messages="errors[0]"
              maxlength="50"
              v-mask="'##'"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="4">
          <ValidationProvider
            :name="$t('transfer_request_business_day')"
            rules="required|max:50|min_value:1|max_value:31"
            v-slot="{ errors }"
          >
            <v-text-field
              :label="`${$t('transfer_request_business_day')} *`"
              dense
              autocomplete="nope"
              v-model="clientRequest.schedule.day"
              :error-messages="errors[0]"
              maxlength="50"
              v-mask="'##'"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="4">
          <ValidationProvider
            :name="$t('transfer_request_end_day')"
            rules="required|max:50"
            v-slot="{ errors }"
          >
            <date-picker-input
              :label="`${$t('transfer_request_end_day')} *`"
              v-model="clientRequest.schedule.end"
              dense
              :min="minDate"
              :error-messages="errors[0]"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
    </template>
  </wizard-form>
</template>
<script>
import WizardForm from '@/components/commons/WizardForm'
import DatePickerInput from '@/components/commons/DatePickerInput'
export default {
  components: {
    WizardForm,
    DatePickerInput
  },
  props: {
    clientRequest: {
      type: [Object]
    }
  },
  computed: {
    minDate() {
      return this.$date()
        .add(1, 'day')
        .format('YYYY-MM-DD')
    }
  },
  methods: {
    handleRadio(event) {
      if (!event) {
        this.$emit('clearSchedule')
      }
    }
  }
}
</script>
<style scoped>
.main-container {
  line-height: normal;
}

hr {
  margin: 0;
  padding: 0;
}

hr.rounded {
  border-top: 2px solid;
  width: 400px;
}
</style>