import BaseService from './base'
import axios from '@/plugins/axios'

export default class ClientTransferRequestService extends BaseService {
  static resource() {
    return 'api/v1/external/clientTransferRequest'
  }

  static async post(accountCode, payload = {}) {
    const { data } = await axios.post(
      `${this.baseURL()}/${this.resource()}/${accountCode}`,
      payload
    )
    return data
  }
}
